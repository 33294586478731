<template>
    <div class="password">

        <h2>Dein neuer Schlüssel zur Sportwelt!</h2>
        <p>Gib’ ein neues Passwort ein und wiederhole es zur Sicherheit. Anschließend kannst du dich mit diesem einloggen.</p>

        <v-text-field name="password" placeholder="Neues Passwort" id="password" type="password" required v-model="password" :rules="passwordRules"></v-text-field>
        <v-text-field name="password_repeat" placeholder="Passwort wiederholen" id="password_repeat" type="password" required v-model="password_repeat" :rules="passwordRules"></v-text-field>

        <small v-show="retryWithPassword" class="text-center" style="margin-bottom: 10px;">Bitte gib zur Bestätigung der Änderung noch dein "altes" Passwort ein.</small>
        <v-text-field v-show="retryWithPassword" v-model="oldPassword" placeholder="Altes Passwort" type="password" required></v-text-field>

        <v-btn :disabled="!validPassword" class="button button--primary" @click="attemptChangePassword">Bestätigen</v-btn>

        <SuccessNotificationModal @closeModal="closeModal" title="Hinweis" message="Das Kennwort wurde geändert."></SuccessNotificationModal>

    </div>
</template>
<script>
import firebase from 'firebase';
import SuccessNotificationModal from '@/components/SuccessNotificationModal.vue';

export default {
    name: 'PasswordChange',
    computed: {
        validPassword() {
            return this.password === this.password_repeat;
        }
    },
    components: { SuccessNotificationModal },
    data() {
        return {
            user: null,
            retryWithPassword: false,
            oldPassword: null,
            password: "",
            password_repeat: "",
            passwordRules: [
                v => !!v || 'Ein Kennwort muss angegeben werden.',
                v =>
                    v.length >= 6 ||
                    'Das Kennwort muss mindestens 6 Zeichen lang sein.'
            ]
        }
    },
    methods: {
        init() {
            this.user = firebase.auth().currentUser;
        },
        attemptChangePassword() {
            if (this.retryWithPassword) {
                var credentials = firebase.auth.EmailAuthProvider.credential(
                    this.user.email,
                    this.oldPassword
                );
                this.user.reauthenticateWithCredential(credentials).then( () => this.changePassword() );
            }
            else {
                this.changePassword();
            }
        },
        changePassword() {
            this.user.updatePassword(this.password).then(() => {
                // Update successful.
                this.$modal.show("successNotification");
            }, (error) => {
                // An error happened.
                console.log(error);
                this.retryWithPassword = true;
            });
        },
        closeModal() {
            this.$router.go(-1);
        }
    },
    mounted() {
        this.init();
    }
};
</script>

<style lang="scss">
    .password {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        text-align: center;
        flex: 1;

        h2 {
            margin: 24px 0 16px;
        }

        p {
            margin-bottom: 32px;
        }

        .button {
            margin-top: auto;
        }
    }
</style>